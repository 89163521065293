import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  text-align: center;
  padding: 48px 36px 67px 36px;

  p {
    font-family: 'Sohne Regular', sans-serif;
  }

  a {
    font-family: inherit;
    text-decoration: none;
    color: #2f80ed;
  }

  @media (min-width: 575px) {
    width: 398px;
  }
`;

export const Header = styled.h1`
  font-size: 21px;
  font-family: 'Sohne Regular', sans-serif;
  font-weight: 700;
`;

export const BackText = styled.p`
  margin: 0;
`;

export const MarginLogo = styled.img`
  display: block;
  margin-top: 12px;
  margin-bottom: 24px;
  height: 36px;
`;

export const EmailSentText = styled.p``;

export const DidntReceiveEmail = styled.p`
  margin-top: 24px;
`;
