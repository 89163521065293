/* eslint-disable consistent-return */
import styled from 'styled-components/macro';
import { AuthTypes } from 'types/auth.types';

export const Root = styled.div<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Container = styled.div<{
  isUpwise?: boolean;
  step?: AuthTypes;
  tokenExpired?: boolean;
}>`
  ${({ isUpwise, step }) => {
    if (isUpwise && step !== AuthTypes.VERIFICATION) {
      return `
        display: flex;  
        flex-direction: column;
      `;
    }
  }}
  padding: ${({ isUpwise }) =>
    isUpwise ? '72px 24px 48px 24px' : '72px 24px 67px 24px'};

  padding-top: ${({ tokenExpired }) => (tokenExpired ? '36px' : '72px')};
  background-color: white;
  text-align: center;
  font-family: ${({ isUpwise }) =>
    isUpwise ? "'Noto Sans', sans-serif" : 'Sohne Regular'};

  h5 {
    padding-bottom: 20px;
    line-height: 1.63;
    height: 26px;
    font-size: 16px;
    margin: 0 0 15px;
    font-family: ${({ isUpwise }) =>
      isUpwise ? "'Noto Sans', sans-serif" : "'Sohne Regular', sans-serif"};
    color: ${({ isUpwise }) => (isUpwise ? '#250e62' : '#343332')};
  }

  p {
    font-family: ${({ isUpwise }) =>
      isUpwise ? "'Noto Sans', sans-serif" : "'Sohne Regular', sans-serif"};
    line-height: 1.63;
    height: 26px;
    text-align: center;
    color: ${({ isUpwise }) => (isUpwise ? '#250e62' : '')};

    a {
      text-decoration: none;
      color: ${({ isUpwise }) => (isUpwise ? '#504be1' : '#003184')};
    }
  }
}

  @media (min-width: 575px) {
    height: ${({ isUpwise }) => (isUpwise ? '100%' : 'unset')};
    width: 398px;
  }
`;

export const TopLogo = styled.img<{ isNayya?: boolean }>`
  display: block;
  margin: auto;
  scale: 0.7;
  padding-bottom: 24px;
  padding-top: 10px;
  margin-top: 24px;
  margin-bottom: 0px;
  min-width: ${({ isNayya }) => (isNayya ? '200px' : '0px')};
`;

export const EmailInput = styled.input`
  display: block;
  margin: auto;
  margin-bottom: 10px;

  border: 1px solid #e0e0de;
  border-radius: 4px;
  margin: 0 auto;
  height: 58px;
  padding: 12px;

  outline: none;
  width: 296px;
  font-size: 16px;
  font-family: 'Sohne Regular', sans-serif;
  line-height: 1.5;
  letter-spacing: -0.32px;

  @media (max-width: 374px) {
    width: 280px;
  }
`;

export const UpwiseEmailInput = styled.input<{ isValidEmail?: boolean }>`
  border: 1px solid #e0e0de;
  border-radius: 4px;
  width: 296px;
  height: 58px;
  padding: 12px;
  margin: 0 auto 24px;
  color: #7c6ea1;
  letter-spacing: -0.32px;
  font-family: 'Noto Sans', sans-serif;
  margin-bottom: ${({ isValidEmail }) => (isValidEmail ? '24px' : '5px')};
  @media (max-width: 374px) {
    width: 280px;
  }
`;

export const ErrorMessage = styled.p`
  && {
    color: red;
  }
  font-size: 13px;
  margin: 0;
`;

export const TermsContainer = styled.div`
  text-align: center;
  max-width: 296px;
  margin: auto;
`;

export const CheckBox = styled.input`
  margin: 5.5px 10px 23px -4px;
  vertical-align: bottom;
  height: 16px;
  width: 30px;
`;

export const Label = styled.label<{ isUpwise?: boolean }>`
  display: flex;
  align-content: flex-start;
  font-size: 15.5px;
  line-height: 1.63;
  width: 296px;
  color: ${({ isUpwise }) => (isUpwise ? '#250e62' : '#1d1d1b')};
  font-family: ${({ isUpwise }) =>
    isUpwise ? "'Noto Sans', sans-serif" : "'Sohne Regular', sans-serif"};

  text-align: left;
  margin: auto;
  user-select: none;
  a {
    color: ${({ isUpwise }) => (isUpwise ? '#504be1' : '#003184')};
    font-size: 15.5px;
    text-decoration: none;
  }
`;

export const RememberEmailContainer = styled.div`
  text-align: left;
  padding: 0px 28px 0px 26px;

  @media (max-width: 575px) {
    width: 300px;
    margin: 10px auto 0 auto;
    padding: 0px;
  }
`;

export const RememberEmailCheckbox = styled.input`
  vertical-align: middle;
  margin: 5.5px 10px 23px -4px;
  height: 16px;
  width: 30px;
`;

export const ButtonContainer = styled.div`
  button {
    @media (min-width: 575px) {
      margin: 36px 0 24px;
    }

    @media (max-width: 374px) {
      width: 272px !important;
    }
  }
`;

export const ButtonStyles = {
  margin: '36px auto 24px',
  width: '300px',
  height: '50px',
  borderRadius: '6px',
  fontSize: '16px',
  fontWeight: 600,
  fontFamily: "'Sohne Regular', sans-serif",
};
