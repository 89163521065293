import React, { MouseEvent } from 'react';
import Arrow from 'assets/images/right-arrow-black.svg';
import Logo from 'assets/images/theme-logo.svg';
import { useTranslate } from 'react-polyglot';
import { BackArrow, BackArrowContainer } from '../SignInPassword/styles';
import {
  BackText,
  Container,
  DidntReceiveEmail,
  EmailSentText,
  Header,
  MarginLogo,
} from './styles';
import { MagicLinkEmailSentProps } from './types';

export const MagicLinkEmailSent = ({
  email,
  setError,
  setPassword,
  setEmail,
  goBack,
}: MagicLinkEmailSentProps): JSX.Element => {
  const handleBackButton = (
    e: MouseEvent<HTMLImageElement> | MouseEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    setEmail('');
    setError('');
    setPassword('');
    goBack();
  };

  const t = useTranslate();

  return (
    <Container>
      <BackArrowContainer onClick={handleBackButton}>
        <BackArrow src={Arrow} />
        <BackText>{t('back_entering_your_email')}</BackText>
      </BackArrowContainer>
      <MarginLogo src={Logo} />
      <Header>An email is on the way</Header>
      <EmailSentText>
        We sent an email to {email} with a password-free link that will log you
        in right away.
      </EmailSentText>

      <DidntReceiveEmail>
        Didn&apos;t receive your email? Check your spam folder or{' '}
        <a href="/users/sign_in">try another email address</a>.
      </DidntReceiveEmail>
    </Container>
  );
};
