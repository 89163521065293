/* eslint-disable react/jsx-props-no-spreading */
import {
  Button as MUIButton,
  ButtonProps,
  makeStyles,
  Theme,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

export interface INayyaButtonProps extends ButtonProps {
  error?: boolean;
  hideIcon?: boolean;
  target?: string;
}

const useStyles = makeStyles<Theme>((theme) => ({
  errorButton: {
    background: theme.palette.error.main,
    color: '#ffffff',

    '&:hover': {
      background: theme.palette.error.main,
    },
  },
  defaultButton: {
    textTransform: 'none',
    background: 'black',
    color: 'white',

    '&.Mui-disabled': {
      color: 'white',
      background: '#C5C5C1',
    },

    '&:hover': {
      background: 'black',
      color: 'white',
    },
  },
}));

const Button: FC<INayyaButtonProps> = ({
  children,
  hideIcon,
  error,
  size,
  className,
  ...other
}) => {
  const classes = useStyles();
  let endIcon;
  if (
    (other.endIcon || other.variant !== 'text') &&
    (!size || size === 'medium' || size === 'large')
  ) {
    endIcon = other.endIcon;
  }

  if (
    !hideIcon &&
    !endIcon &&
    other.variant !== 'text' &&
    other.variant !== undefined
  ) {
    endIcon = <ChevronRightIcon />;
  }
  return (
    <MUIButton
      {...other}
      size={size}
      endIcon={endIcon}
      className={clsx([
        error ? classes.errorButton : '',
        classes.defaultButton,
        className,
      ])}
    >
      {children}
    </MUIButton>
  );
};

export default Button;
export { Button as NayyaButton };
