import { NayyaLoaderButton } from 'components/base/Buttons/LoaderButton';
import CenteredTextLine from 'components/shared/CenteredTextLine/CenteredTextLine';
import * as React from 'react';
import { useState, MouseEvent, KeyboardEvent } from 'react';
import EyeBallHide from 'assets/images/eyeball-hide.svg';
import EyeBallShow from 'assets/images/eyeball-show.svg';
import Logo from 'assets/images/theme-logo.svg';
import Arrow from 'assets/images/right-arrow-black.svg';
import { useCustomTheme } from 'providers/CustomThemeProvider';
import { TopLogo } from 'styles/auth';
import get from 'lodash/get';
import { sendMagicLinkEmail } from 'services/auth';
import { useMutation } from '@tanstack/react-query';
import { useCsrf } from 'hooks/useCsrf';
import { trackHeapEvent } from 'helpers/analytics';
import { captureException } from '@sentry/react';
import { useTranslate } from 'react-polyglot';
import { CreateAccountPrompt } from '../CreateAccountPrompt';
import { ForgotPassword } from '../ForgotPassword';
import {
  BackArrow,
  BackArrowContainer,
  ButtonContainer,
  ButtonStyles,
  ErrorMessage,
  ExpiredTokenContainer,
  LogInContainer,
  PasswordContainer,
  PasswordInput,
  PasswordToggle,
  WarningMessage,
  LogInWIthoutPasswordButton,
  EmailGreeting,
} from './styles';
import { ISignInPasswordProps } from './types';
import { MagicLinkEmailSent } from '../MagicLinkEmailSent/MagicLinkEmailSent';

const SignInPassword = ({
  email,
  error,
  isSubmitting,
  handleSubmit,
  setError,
  setPassword,
  setEmail,
  goBack,
}: ISignInPasswordProps): JSX.Element => {
  const handleBackButton = (
    e: MouseEvent<HTMLImageElement> | MouseEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    setEmail('');
    setError('');
    setPassword('');
    goBack();
  };
  const params = new URLSearchParams(window.location.search);
  const tokenExpired = params.get('token_expired') === 'true';
  const { themeBrandName } = useCustomTheme();

  const [showPassword, setShowPassword] = useState(false);
  const [capsLockOn, setCapsLockOn] = useState(false);
  const [showMagicLinkSent, setShowMagicLinkSent] = useState(false);

  const { csrfToken } = useCsrf();
  const { mutateAsync: sendMagicLink } = useMutation(sendMagicLinkEmail);

  const t = useTranslate();

  const checkCapsEvent = (e: KeyboardEvent<HTMLInputElement>) => {
    setCapsLockOn(e.getModifierState('CapsLock'));
  };

  const handleLoginWithoutPassword = async (
    e: MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    if (!!csrfToken && !!email) {
      try {
        await sendMagicLink({ email, authenticityToken: csrfToken });

        setShowMagicLinkSent(true);
        trackHeapEvent('MagicLink:Button:Click', { email });
      } catch (err) {
        captureException(err);
      }
    }
  };

  if (showMagicLinkSent) {
    return (
      <MagicLinkEmailSent
        email={email}
        setError={setError}
        setPassword={setPassword}
        setEmail={setEmail}
        goBack={goBack}
      />
    );
  }

  return (
    <LogInContainer tokenExpired={tokenExpired}>
      {tokenExpired ? (
        <ExpiredTokenContainer>
          Your one-time link is invalid or has expired.
        </ExpiredTokenContainer>
      ) : (
        <BackArrowContainer onClick={handleBackButton}>
          <BackArrow src={Arrow} />
          {t('back_entering_your_email')}
        </BackArrowContainer>
      )}

      <TopLogo src={Logo} isNayya />
      <EmailGreeting isLongEmail={email.length > 35}>
        Hello, {email}!
      </EmailGreeting>
      <WarningMessage>{capsLockOn ? 'Caps Lock is on' : ''}</WarningMessage>

      <PasswordContainer>
        <PasswordInput
          autoFocus
          autoComplete="current-password"
          id="user_password"
          type={showPassword ? 'text' : 'password'}
          placeholder="Enter your password"
          onChange={(e) => {
            setPassword(e.target.value);
            setError('');
          }}
          onKeyDown={checkCapsEvent}
          onKeyUp={checkCapsEvent}
        />
        <PasswordToggle
          onClick={() => setShowPassword(!showPassword)}
          src={showPassword ? EyeBallHide : EyeBallShow}
        />
      </PasswordContainer>
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}

      <ButtonContainer>
        <NayyaLoaderButton
          id="log_in_button"
          style={ButtonStyles}
          isLoading={isSubmitting}
          onClick={handleSubmit}
        >
          Log in
        </NayyaLoaderButton>
      </ButtonContainer>

      <CreateAccountPrompt themeBrandName={themeBrandName} />
      <ForgotPassword email={email} />

      <CenteredTextLine text="or" />
      <ButtonContainer>
        <NayyaLoaderButton
          id="magic_link_button"
          style={LogInWIthoutPasswordButton}
          isLoading={isSubmitting}
          onClick={handleLoginWithoutPassword}
          hideIcon
        >
          Log in without a password
        </NayyaLoaderButton>
      </ButtonContainer>
    </LogInContainer>
  );
};

export default SignInPassword;
