import React from 'react';
import { Themes } from 'types/auth.types';
import styled from 'styled-components/macro';

const UpwiseP = styled.p`
  a {
    font-family: 'Noto Sans', sans-serif;
    color: #2f80ed;
  }
`;

const NayyaP = styled.p`
  a {
    color: #003184;
  }
`;

const ForgotPassword = ({
  email,
  theme,
}: {
  email: string;
  theme?: Themes;
}): JSX.Element => {
  const forgetPasswordUrl = `${
    window.location.origin
  }/users/password/new?email=${encodeURIComponent(email)}`;

  if (theme === Themes.UPWISE) {
    return (
      <UpwiseP>
        <a href={forgetPasswordUrl}> Forgot Password?</a>
      </UpwiseP>
    );
  }

  return (
    <NayyaP>
      <a href={forgetPasswordUrl}> Forgot Password?</a>
    </NayyaP>
  );
};

export { ForgotPassword };
