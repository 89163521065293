import styled from 'styled-components/macro';

export const ContainerDiv = styled.div<{
  isNayya: boolean;
}>`
  background-color: white;
  height: 'unset';
  flex: 1;
  width: 100vw;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 575px) {
    background-color: ${({ isNayya }) => (isNayya ? 'white' : '#faf8f7')};
    display: flex;
  }

  @media (max-height: 500px) {
    display: unset;
  }
`;

export const Wrapper = styled.div<{ isNayya: boolean }>`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  flex: ${({ isNayya }) => (isNayya ? '1' : 'unset')};

  * {
    box-sizing: border-box;
  }

  @media (min-width: 575px) {
    height: ${({ isNayya }) => (isNayya ? 'unset' : '579px')};
    flex-direction: row;
  }
  @media (max-width: 575px) {
    margin-bottom: ${({ isNayya }) => (isNayya ? '0' : '24px')};
    height: ${({ isNayya }) => (isNayya ? 'unset' : 'auto')};
  }
`;

export const InvitationWrapper = styled.div`
  * {
    box-sizing: border-box;
  }
`;

export const ResetPasswordWrapper = styled.div`
  * {
    box-sizing: border-box;
  }
`;
