import SignInForm from 'pages/auth-screens/SignInForm';
import SignUpForm from 'pages/auth-screens/SignUpForm';
import * as React from 'react';
import { SupportedThemes, Themes } from 'types/auth.types';
import AuthFooter from 'pages/auth-screens/AuthFooter';
import { ApplicationProviders } from 'providers/ApplicationProviders';
import { Root } from 'styles/auth';
import { UpwiseDisclaimerText } from 'pages/auth-screens/DisclaimerText/upwise/DisclaimerText';
import { ContainerDiv, Wrapper } from './styles';

const Auth = <T extends SupportedThemes, S>(
  { theme }: T,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _railsContext: S,
): (() => JSX.Element) => {
  const url = window.location.pathname;

  const isNayya = theme === Themes.DEFAULT;

  return () => (
    <Root backgroundColor="#faf8f7">
      <ApplicationProviders theme={theme}>
        <ContainerDiv isNayya={isNayya}>
          <Wrapper isNayya={isNayya}>
            {url === '/users/sign_up' ? <SignUpForm /> : <SignInForm />}
          </Wrapper>
          {theme === Themes.UPWISE && <UpwiseDisclaimerText />}
        </ContainerDiv>
        <AuthFooter />
      </ApplicationProviders>
    </Root>
  );
};

export default Auth;
