import React from 'react';
import { TopLogo } from 'styles/auth';
import NayyaLogo from 'assets/images/theme-logo.svg';
import UpwiseLogo from 'assets/images/upwise/theme-logo.svg';
import { Themes } from 'types/auth.types';

const ThemedLogo = ({
  theme,
  url,
}: {
  theme?: Themes;
  url?: string;
}): JSX.Element => {
  if (url) {
    return <TopLogo src={url} />;
  }

  switch (theme) {
    case Themes.UPWISE:
      return <TopLogo src={UpwiseLogo} data-testid="upwise-logo" />;
    default:
      return <TopLogo src={NayyaLogo} data-testid="nayya-logo" isNayya />;
  }
};

export default ThemedLogo;
