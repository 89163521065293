import styled from 'styled-components/macro';

export const StyledForm = styled.form<{
  isNayya: boolean;
}>`
  @media (min-width: 576px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    background-color: white;
    width: ${({ isNayya }) => (isNayya ? '50vw' : '398px')};
  }

  @media (max-width: 575px) {
    height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;
