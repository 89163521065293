import { NayyaLoaderButton } from 'components/base/Buttons/LoaderButton';
import * as React from 'react';
import { FormEvent, ReactNode, useState } from 'react';
import ThemedLogo from 'components/shared/ThemedLogo';
import {
  ButtonContainer,
  ButtonStyles,
  CheckBox,
  Container,
  EmailInput,
  ErrorMessage,
  Label,
  RememberEmailCheckbox,
  RememberEmailContainer,
  TermsContainer,
  UpwiseEmailInput,
} from 'styles/auth';
import { AuthTypes, Themes } from 'types/auth.types';
import UpwiseButton from 'components/base/Buttons/UpwiseButton';
import Cookies from 'js-cookie';
import { isValidEmail as validateEmailFormatting } from 'helpers/validation-utils';
import { StyledForm } from './SharedStyledComponents';

const getHeading = ({
  authType,
  themeName,
}: {
  authType: AuthTypes;
  themeName?: string;
}) => {
  switch (authType) {
    case AuthTypes.SIGN_IN:
      return `Welcome back! Sign in to ${themeName}.`;

    case AuthTypes.SIGN_UP:
      return `Welcome! Create your ${themeName} account.`;

    case AuthTypes.VERIFICATION:
      return "Verify it's you! We've sent verification instructions to the email address you provided.";
    default:
      return '';
  }
};

interface AuthInputContainerProps {
  children?: ReactNode;
  authType: AuthTypes;
  email?: string;
  heading?: string;
  isValidEmail?: boolean;
  requiredServiceTermsChecked?: boolean;
  requiredServiceTerms?: {
    end_user_content: string;
    privacy_policy_content: string;
  } | null;
  handleClick?: (
    e: FormEvent | null,
    isFirstTimeCheck: boolean | undefined,
  ) => void;
  setEmail?: (email: string) => void;
  setRememberedEmail?: (save: boolean) => void;
  rememberedEmail?: boolean;
  setRequiredServiceTermsChecked?: (
    setRequiredServiceTermsChecked: boolean,
  ) => void;
  theme?: Themes;
}

const AuthInputContainer = ({
  children,
  authType,
  email,
  heading,
  isValidEmail,
  requiredServiceTermsChecked,
  requiredServiceTerms,
  handleClick,
  setEmail,
  setRememberedEmail,
  rememberedEmail,
  setRequiredServiceTermsChecked,
  theme,
}: AuthInputContainerProps): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isUpwise = theme === Themes.UPWISE;

  const themeName = isUpwise ? 'Upwise' : 'Nayya';

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (handleClick) {
      handleClick(e, false);
    }
    setIsSubmitting(!isSubmitting);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (validateEmailFormatting(e.target.value)) {
      Cookies.set('email', encodeURIComponent(e.target.value));
    }

    if (setEmail) {
      setEmail(e.target.value);
    }
  };

  const emailInput = () => {
    if (isUpwise) {
      return (
        <UpwiseEmailInput
          autoFocus
          autoComplete="email"
          name="email"
          type="email"
          placeholder="Email"
          id="user_email"
          defaultValue={email}
          onChange={handleEmailChange}
          isValidEmail={isValidEmail}
        />
      );
    }

    return (
      <EmailInput
        autoFocus
        autoComplete="email"
        name="email"
        type="email"
        placeholder="Email"
        id="user_email"
        defaultValue={email}
        onChange={handleEmailChange}
      />
    );
  };

  switch (authType) {
    case AuthTypes.VERIFICATION:
      return (
        <StyledForm isNayya={theme === Themes.DEFAULT}>
          <Container isUpwise={isUpwise} step={AuthTypes.VERIFICATION}>
            <ThemedLogo theme={theme} />
            <h5>{heading || getHeading({ themeName, authType })}</h5>
          </Container>
        </StyledForm>
      );

    default:
      return (
        <StyledForm isNayya={theme === Themes.DEFAULT}>
          <Container isUpwise={isUpwise}>
            <ThemedLogo theme={theme} />
            <h5>{getHeading({ themeName, authType })}</h5>

            {emailInput()}

            {!isValidEmail && email && email.length > 4 && (
              <ErrorMessage>Please enter a valid email address.</ErrorMessage>
            )}

            {authType === AuthTypes.SIGN_IN && (
              <RememberEmailContainer>
                <Label isUpwise={isUpwise}>
                  <RememberEmailCheckbox
                    checked={rememberedEmail}
                    type="checkbox"
                    onChange={
                      setRememberedEmail &&
                      ((e: React.ChangeEvent<HTMLInputElement>) =>
                        setRememberedEmail(e.target.checked))
                    }
                  />
                  <p>Remember my email</p>
                </Label>
              </RememberEmailContainer>
            )}

            {Boolean(
              authType === AuthTypes.SIGN_UP && requiredServiceTerms,
            ) && (
              <TermsContainer>
                <Label isUpwise={isUpwise}>
                  <CheckBox
                    id="consent_checkbox"
                    type="checkbox"
                    onClick={
                      setRequiredServiceTermsChecked &&
                      (() =>
                        setRequiredServiceTermsChecked(
                          !requiredServiceTermsChecked,
                        ))
                    }
                  />
                  <span>
                    {themeName === 'Upwise'
                      ? 'I agree to the '
                      : "I agree to Nayya's "}
                    <a href={requiredServiceTerms?.end_user_content}>
                      Terms of Service
                    </a>{' '}
                    and
                    <a href={requiredServiceTerms?.privacy_policy_content}>
                      {' '}
                      Privacy Policy
                    </a>
                  </span>
                </Label>
              </TermsContainer>
            )}

            <ButtonContainer>
              {!isUpwise && (
                <NayyaLoaderButton
                  id="next_button"
                  style={ButtonStyles}
                  isLoading={isSubmitting}
                  disabled={!isValidEmail || !requiredServiceTermsChecked}
                  onClick={handleSubmit}
                >
                  Next
                </NayyaLoaderButton>
              )}

              {isUpwise && (
                <UpwiseButton
                  id="next_button"
                  disabled={!isValidEmail || !requiredServiceTermsChecked}
                  isLoading={isSubmitting}
                  onClick={handleSubmit}
                >
                  Next
                </UpwiseButton>
              )}
            </ButtonContainer>
            {children}
          </Container>
        </StyledForm>
      );
  }
};

export default AuthInputContainer;
