import AuthSideImagem from 'assets/images/auth-side-image.png';
import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  position: relative;
  width: 100vw;

  @media (max-width: 576px) {
    height: 100%;
  }

  @media (min-width: 576px) {
    width: 50vw;
    flex: 1;
    /* 100vh - 60px (footer) */
    max-height: calc(100vh - 60px);
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CenteredText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  text-align: center;
`;

const NayyaAuthLeftSide = (): JSX.Element => {
  const text = 'Experience a new way to manage your benefits all year round.';
  return (
    <Container>
      <Image src={AuthSideImagem} alt="AuthSideImagem" />
      <CenteredText>{text}</CenteredText>
    </Container>
  );
};

export default NayyaAuthLeftSide;
