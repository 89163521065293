import * as React from 'react';
import { useTranslate } from 'react-polyglot';
import styled from 'styled-components/macro';
import BlueCheck from 'assets/images/upwise-blue-check.svg';
import Claims from 'assets/images/nayya-claims.svg';
import { Themes } from '../../types/auth.types';

const Container = styled.div<{ isUpwise: boolean }>`
  background-color: #cee5fd;
  padding: ${({ isUpwise }) =>
    isUpwise ? '40px 40px 10px 40px' : '72px 24px 67px'};
  position: relative;
  display: block;
  color: ${({ isUpwise }) => {
    return isUpwise ? '#250E62' : '';
  }};

  text-align: ${({ isUpwise }) => (isUpwise ? 'left' : 'center')};

  p {
    margin: 12px 0 12px 12px;
    line-height: 1.63;
    color: inherit;
    align-items: center;
    display: flex;
    white-space: normal;
  }

  @media (min-width: 575px) {
    width: 384px;
    height: 100%;
  }
`;

const Heading = styled.h4<{ isUpwise: boolean }>`
  padding: ${({ isUpwise }) => (isUpwise ? '' : '')};
  font-family: ${({ isUpwise }) =>
    isUpwise ? "'Laca', sans-serif" : "'Sohne Regular', sans-serif"};
  font-weight: 600;
  font-size: 28px;
  color: inherit;
`;

const PointContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`;

const NayyaClaimsContainer = styled.div`
  margin-top: 36px;
  position: relative;
  padding-bottom: 50px;
`;

const NayyaClaims = styled.img`
  width: 100%;

  @media (min-width: 575px) {
    position: absolute;
    left: -110px;
  }

  @media (max-height: 500px) {
    position: unset;
    left: 0px;
  }
`;

const UpwisePoints = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 12px 0 24px 0;

  && {
    color: #250e62;
  }

  p {
    text-indent: 0;
    font-family: 'Noto Sans', sans-serif;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
  }
`;

const UpwiseUL = styled.ul`
  margin-left: 15px;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const UpwiseLI = styled.li`
  display: flex;
  margin-top: 10px;
  align-items: flex-start;

  p {
    line-height: 24px;
    margin: 0;
    margin-left: 10px;
    font-size: 14px;
    text-indent: 0;
    font-weight: 700;
  }
`;

const DefaultPoints = styled.div`
  p {
    font-size: 14px;
    font-weight: 400;
  }
`;

const UpwiseBullet = ({ text }: { text: string }) => {
  return (
    <UpwiseLI>
      <img src={BlueCheck} alt="" />
      <p>{text}</p>
    </UpwiseLI>
  );
};

const PointsContainer = ({ theme }: { theme?: Themes }): JSX.Element => {
  const t = useTranslate();

  const isUpwise = theme === Themes.UPWISE;

  const paragraphComponent = () => {
    if (isUpwise) {
      return (
        <UpwisePoints>
          <p>{t('points.upwise.paragraph')}</p>

          <UpwiseUL>
            <UpwiseBullet text={t('points.upwise.additional_phrases.first')} />
            <UpwiseBullet text={t('points.upwise.additional_phrases.second')} />
          </UpwiseUL>
        </UpwisePoints>
      );
    }

    return (
      <DefaultPoints>
        <p>{t('points.paragraph')}</p>
      </DefaultPoints>
    );
  };

  return (
    <Container isUpwise={isUpwise}>
      <Heading isUpwise={isUpwise}>
        {isUpwise ? t('points.upwise.heading') : t('points.heading')}
      </Heading>

      <PointContainer>{paragraphComponent()}</PointContainer>

      <NayyaClaimsContainer>
        <NayyaClaims src={Claims} />
      </NayyaClaimsContainer>
    </Container>
  );
};

export default PointsContainer;
