import styled from 'styled-components/macro';
import {
  Container as BaseContainer,
  Header as BaseHeader,
  BackText as BaseBackText,
  EmailSentText as BaseEmailSentText,
  DidntReceiveEmail as BaseDidntReceiveEmail,
} from '../styles';

export const Container = styled(BaseContainer)`
  p {
    color: #250e62;
    font-family: 'Noto Sans', sans-serif;
  }

  a {
    color: #504be1;
  }
`;

export const Header = styled(BaseHeader)`
  color: #250e62;
  font-family: 'Sohne Regular', sans-serif;
`;

export const BackText = styled(BaseBackText)`
  font-size: 14px;
`;

export const EmailSentText = styled(BaseEmailSentText)`
  font-size: 16px;
`;

export const DidntReceiveEmail = styled(BaseDidntReceiveEmail)`
  font-size: 16px;
`;
