import styled from 'styled-components/macro';

const FooterContainer = styled.div<{ isNayya: boolean }>`
  width: 100vw;
  padding: 10px 0;
  background-color: ${({ isNayya }) => (isNayya ? 'black' : 'initial')};

  @media (min-width: 575px) {
    bottom: 0;
    padding: 20px 0;
  }

  @media (max-height: 500px) {
    bottom: initial;
  }
`;

const Col = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
`;

const FooterInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 575px) {
    flex-direction: row;
  }
`;

const Text = styled.p<{ isNayya: boolean }>`
  ${({ theme, isNayya }) => {
    const styles = theme.typography.footer;
    return `
      font-family: ${styles.fontFamily};
      color: ${isNayya ? 'white' : styles.color};
    `;
  }}
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  margin-right: 0;
  margin-bottom: 0;

  @media (min-width: 575px) {
    margin-right: 44px;
  }
`;

const List = styled.ul`
  display: flex;
  margin: 0 -22px;
`;

const Item = styled.li`
  padding: 0 22px;
  list-style: none;
`;

const Link = styled.a<{ isNayya: boolean }>`
  ${({ theme, isNayya }) => {
    const styles = theme.typography.footer;
    return `
      font-family: ${styles.fontFamily};
      color: ${isNayya ? 'white' : styles.color};
    `;
  }}
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  text-decoration: none;
`;

export { FooterContainer, Col, FooterInner, Text, List, Item, Link };
