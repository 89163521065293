import React from 'react';
import styled from 'styled-components/macro';

const StyledP = styled.p`
  color: #1d1d1b;
`;

const AlreadyHaveAccountPrompt = (): JSX.Element => {
  return (
    <StyledP>
      Already have an account?
      <a href={`${window.location.origin}/users/sign_in`}> Log in.</a>
    </StyledP>
  );
};

export { AlreadyHaveAccountPrompt };
