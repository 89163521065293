import axios from 'helpers/axios';
import * as React from 'react';
import { FormEvent, RefObject, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { AuthTypes, Steps, Themes } from 'types/auth.types';
import { useCustomTheme } from 'providers/CustomThemeProvider';
import { useCsrf } from 'hooks';
import AuthInputContainer from './AuthInputContainer';
import Loading from './Loading';
import { checkIsValidEmail, delegateValidEmail } from './MultiFactorAuth';
import PointsContainer from './PointsContainer';
import { StyledForm } from './SharedStyledComponents';
import SignInPassword from './SignInPassword/SignInPassword';
import UpwiseSignInPassword from './SignInPassword/upwise/SignInPassword';
import SignInEmail from './SignInEmail';
import NayyaAuthLeftSide from './NayyaAuthLeftSide';

const SignInForm = (): JSX.Element => {
  const { theme } = useCustomTheme();
  const [step, setStep] = useState<Steps>(Steps.EMAIL);
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [rememberedEmail, setRememberedEmail] = useState(
    !!localStorage.getItem('email'),
  );
  const [password, setPassword] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const reRef: RefObject<ReCAPTCHA> = useRef<ReCAPTCHA>(null);
  const siteKeyMetaTag: HTMLMetaElement | null = document?.querySelector(
    "meta[name='recaptcha-site-key']",
  );

  const { csrfToken } = useCsrf();

  const handleCheckSso = async (
    e: FormEvent | null,
    isFirstTimeCheck: boolean | undefined,
  ) => {
    setStep(Steps.LOADING);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    e && e.preventDefault();

    const authType = AuthTypes.SIGN_IN;
    const url = '/sso_options';
    if (!email) {
      setStep(Steps.EMAIL);
    } else {
      const response = await axios.post(url, { email, authType });
      const { data } = response;

      if (isFirstTimeCheck && data) {
        setStep(Steps.EMAIL);
      } else {
        const result = await delegateValidEmail(data, email, AuthTypes.SIGN_IN);

        if (result.mfaEnabledAtSignin) {
          setStep(Steps.VERIFICATION);
        } else if (result.showLoadingScreen) {
          setStep(Steps.LOADING);
        } else {
          setStep(Steps.PASSWORD);
        }
      }
    }
  };

  useEffect(() => {
    if (rememberedEmail && email) {
      handleCheckSso(null, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(!isSubmitting);

    if (password.length === 0) {
      setError('Invalid password');
      setIsSubmitting(false);
      return;
    }

    const passwordAttemptsUrl = '/failed_password_attempts';
    const passwordAttemptsResponse = await axios.post(passwordAttemptsUrl, {
      email,
      password,
    });
    const passwordAttemptsData = passwordAttemptsResponse.data;
    let recaptchaToken;

    if (reRef.current && passwordAttemptsData.status === 400) {
      setError(passwordAttemptsData.error);
      setIsSubmitting(false);
      recaptchaToken = await reRef.current.executeAsync();
      reRef.current.reset();
    }

    const url = '/users/sign_in';
    const options = {
      method: 'POST',
      body: JSON.stringify({
        user: { email, password },
        authenticity_token: csrfToken,
        recaptchaToken,
      }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };

    const response = await fetch(url, options);

    const jwtExpiration = response.headers.get('JWT-Expiration');
    if (jwtExpiration) {
      localStorage.setItem('JWT_EXPIRATION', jwtExpiration);
    }

    const data = await response.json();

    if (data.redirect_url) {
      if (rememberedEmail) {
        localStorage.setItem('email', email);
      } else {
        localStorage.setItem('email', '');
      }
      window.location.replace(`${window.origin}${data.redirect_url}`);
    } else {
      setError(data.error);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    checkIsValidEmail(email, setIsValidEmail);
  }, [email]);

  const isUpwise = theme === Themes.UPWISE;

  const passwordComponent = () => {
    if (isUpwise) {
      return (
        <UpwiseSignInPassword
          email={email}
          error={error}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          setEmail={setEmail}
          setError={setError}
          setPassword={setPassword}
          goBack={() => setStep(Steps.EMAIL)}
        />
      );
    }

    return (
      <SignInPassword
        email={email}
        error={error}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        setEmail={setEmail}
        setError={setError}
        setPassword={setPassword}
        goBack={() => setStep(Steps.EMAIL)}
      />
    );
  };

  const leftSideContent = () => {
    if (isUpwise) return <PointsContainer theme={theme} />;
    return <NayyaAuthLeftSide />;
  };

  return (
    <>
      {leftSideContent()}

      <StyledForm isNayya={theme === Themes.DEFAULT}>
        <input
          type="hidden"
          name="authenticity_token"
          value={csrfToken || ''}
        />

        <ReCAPTCHA
          sitekey={siteKeyMetaTag?.content || ''}
          size="invisible"
          ref={reRef as RefObject<ReCAPTCHA> & RefObject<HTMLDivElement>}
          badge="bottomleft"
        />

        {step === Steps.LOADING && <Loading />}

        {step === Steps.EMAIL && (
          <SignInEmail
            email={email}
            isValidEmail={isValidEmail}
            handleCheckSso={handleCheckSso}
            setEmail={setEmail}
            setRememberedEmail={setRememberedEmail}
            rememberedEmail={rememberedEmail}
          />
        )}

        {step === Steps.PASSWORD && passwordComponent()}

        {step === Steps.VERIFICATION && (
          <AuthInputContainer authType={AuthTypes.VERIFICATION} />
        )}
      </StyledForm>
    </>
  );
};

export default SignInForm;
